html {
    --primary: #189f96;
    --black: #101010;
    --white: #ffffff;
    --gray-0: #fafafa;
    --inactive: #d9d9d9;
    --margin: 32px;

    --f-smaller: 0.8rem;
    --f-small: 0.9rem;
    --f-medium: 1rem;
    --f-large: 1.1rem;
    --f-larger: 1.2rem;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: var(--black);
    background-color: var(--white);
    font-size: var(--f-small);
}

* {
    box-sizing: border-box;
}

span {
    word-break: keep-all;
}

.topnav {
    height: 50px;
    background-color: var(--primary);
}

.step {
    width: 2em;
    min-width: 26px;
    height: 2em;
    min-height: 26px;
    border-radius: 50%;
    border-width: 2px;
    border-style: solid;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: bold;
}
.step.active {
    border-color: var(--primary);
    background-color: var(--primary);
    color: var(--white);
}
.step.inactive {
    border-color: var(--inactive);
    background-color: var(--white);
    color: var(--inactive);
}

.dot {
    width: 5px;
    height: 5px;
    border-radius: 2.5px;
}
.dot.active {
    background-color: var(--primary);
}
.dot.inactive {
    background-color: var(--inactive);
}

.dot + .dot {
    margin-left: 6px;
}
.dot + .step {
    margin-left: 6px;
}
.step + .dot {
    margin-left: 6px;
}

.image-frame {
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.form-input {
    height: 36px;
    padding-left: 13px;
    border-radius: 4px;
    border: 1px solid #cacaca;
}
.form-button,
.rigid-button {
    height: 36px;
    border: 0;
    border-radius: 4px;
    background-color: var(--primary);
    color: var(--white);
    font-size: var(--f-small);
    font-weight: 800;
}

.rigid-button:disabled {
    background-color: #cacaca;
    color: var(--white);
}

.form-input + .form-input {
    margin-top: 10px;
}
.form-input + .form-button {
    margin-top: 20px;
}

.drawer-open {
    animation-name: drawer;
    animation-duration: 400ms;
    animation-fill-mode: forwards;
}
.drawer-closed {
    animation-name: drawer-reverse;
    animation-duration: 400ms;
    animation-fill-mode: forwards;
}

@keyframes drawer {
    0% {
        opacity: 0;
        transform: translateX(0);
        pointer-events: none;
    }
    100% {
        opacity: 1;
        transform: translateX(-50%);
        pointer-events: all;
    }
}

@keyframes drawer-reverse {
    0% {
        opacity: 1;
        transform: translateX(-50%);
        pointer-events: all;
    }
    100% {
        opacity: 0;
        transform: translateX(0);
        pointer-events: none;
    }
}

.audioList {
    width: calc(100% - 2 * var(--margin));
    background-color: #fafafa;
    border-radius: 10px;
    border: 1px #ededed solid;
    padding: 22px 20px;
    margin-top: 23px;
}

.audioItem {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 36px;
    border-radius: 10px;
    background-color: var(--white);
    cursor: pointer;
}
.audioItem > .info > .index {
    margin-left: 8px;
    width: 27px;
    text-align: right;
    font-weight: 700;
    font-size: var(--f-medium);
    color: var(--primary);
}
.audioItem > .info > .title {
    margin-left: 10px;
    font-weight: 700;
    font-size: var(--f-smaller);
    color: var(--black);
}

.audioItem > .credit {
    font-weight: 700;
    font-size: 11px;
    margin-right: 11px;
    color: #555;
}

.audioItem.selected {
    background-color: var(--primary);
}
.audioItem.selected > .info > .index {
    color: var(--white);
}
.audioItem.selected > .info > .title {
    color: var(--white);
}

.audioItem.selected > .credit {
    color: var(--white);
}

.audioItem + .audioItem {
    margin-top: 8px;
}


.charge-button {
    height: 36px;
    border: 0;
    border-radius: 4px;
    background-color: var(--primary);
    color: var(--white);
    font-size: var(--f-small);
    font-weight: 800;
}

.charge-button:disabled {
    background-color: #cacaca;
    color: var(--white);
}